body {
  margin: 0;
  font-family: "Noto Sans CJK KR";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-family: "Noto Sans CJK KR";
}

code {
  font-family: "Noto Sans CJK KR", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 100;
  src: url("styles/fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("styles/fonts/NotoSansKR-Light.woff") format("woff"),
    url("styles/fonts/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: normal;
  src: url("styles/fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("styles/fonts/NotoSansKR-Regular.woff") format("woff"),
    url("styles/fonts/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: 500;
  src: url("styles/fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("styles/fonts/NotoSansKR-Medium.woff") format("woff"),
    url("styles/fonts/NotoSansKR-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Noto Sans CJK KR";
  font-style: normal;
  font-weight: bold;
  src: url("styles/fonts/NotoSansKR-Bold.woff2") format("woff2"),
    url("styles/fonts/NotoSansKR-Bold.woff") format("woff"),
    url("styles/fonts/NotoSansKR-Bold.otf") format("truetype");
}
